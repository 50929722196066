import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

// import { Slider } from "../components/Slider"
import Stage from "../components/Stage"
import { BlueBanner } from "../components/Banner"

import Checklist from "../components/Checklist"

import Grid from "../components/Grid"
import Container from "../components/Container"
import { Button, TextLink } from "../components/Button"
import { Globe, Connections, Monitor, Brain } from "../components/Icon"
import { Simple as Card } from "../components/Card"

import Teaser from "../components/Teaser"
import ModalVideo from "../components/ModalVideo"

import ArticlePreview from "../components/ArticlePreview"

import CustomerCarousel from "../components/CustomerCarousel"

import IntroductionToCloudFleetManagerLoopMp4Video from "../videos/Introduction-to-CFM-Loop-360p.mp4"
import IntroductionToCloudFleetManagerLoopWebMVideo from "../videos/Introduction-to-CFM-Loop-360p.webm"

import AiFeaturesMp4Video from "../videos/cfm-ai-features.mp4"
import AiFeaturesPreviewMp4Video from "../videos/cfm-ai-features-preview.mp4"
import AiFeaturesPreviewWebMVideo from "../videos/cfm-ai-features-preview.webm"
import AiFeaturesPosterImage from "../videos/cfm-ai-features-poster.jpg"

const rainbow = ["pictonblue", "solaris", "atlantis", "tealblue"]

const IndexPage = ({
	data: {
		page,
		customers,
		allCustomers,
		testimonials,
		allPressCoverage,
		recentBlog,
		bannerImage,
		breakerImage,
		brochureImage,

		popularApplications,
	},
}) => {
	const Text = () => (
		<div className="flex flex-col xl:mr-30 lg:pb-[60px] xl:pb-[95px]">
			<h1 className="display1 text-white xl:pr-30">
				The AI-powered ship management solution
			</h1>
			<h3 className="display3 reg text-white mt-20 lg:w-3/4">
				All-in-one software for shipping companies that works entirely in web-browsers.
			</h3>
		</div>
	)

	const Video = () => (
		<ModalVideo
			event="video-introduction-to-cloud-fleet-manager"
			className="absolute top-0 -bottom-60 left-0 right-0"
			poster={page.introductionVideo.posterImage.localFile.publicURL}
			rootElement="modal-root"
			previewSources={[
				{
					src: IntroductionToCloudFleetManagerLoopMp4Video,
					type: "video/mp4",
				},
				{
					src: IntroductionToCloudFleetManagerLoopWebMVideo,
					type: "video/webm; codecs=vp9,vorbis",
				},
			]}
			sources={[
				{
					src: page.introductionVideo.video.localFile.url,
					type: "video/mp4",
				},
			]}
		/>
	)

	return (
		<Layout translucent={true}>
			{/* description={page.seo.description} */}
			<Seo title={page.seo.title} keywords={page.seo.keywords} />

			<Stage name="hero" className="overflow-visible">
				<div className="xl:container px-10 xl:px-30">
					<div className="px-10 md:px-20 pt-[130px] relative">
						<div className="grid grid-cols-12 gap-x-20 gap-y-50 md:gap-x-40 relative">
							<div className="relative col-span-12 lg:col-span-7 xl:col-span-6 z-10">
								<Text />
							</div>
							<div className="-mb-100 md:-mb-120 lg:mb-0 relative w-full aspect-w-16 aspect-h-9 col-span-12 lg:col-start-6 xl:col-start-6 lg:col-span-7 xl:col-span-7 lg:z-0 lg:absolute lg:inset-0 lg:aspect-none z-0">
								<Video />
							</div>
						</div>
					</div>
				</div>
			</Stage>

			<div className="flex flex-col gap-y-80 md:gap-y-100 lg:gap-y-120">
				<div className="bg-porcelain pt-100 md:pt-120 lg:pt-0">
					<Grid className="py-spacer-xl">
						<div className="col-span-12 bg-white rounded-medium p-30 flex flex-col gap-y-30">
							<div className="flex flex-row justify-between items-center">
								<h1 className="display3">Recent Insights</h1>
								<TextLink to="/blog">View all</TextLink>
							</div>
							<div className="flex flex-col xl:flex-row gap-30">
								<div className="flex-1">
									<div className="bg-pictonblue bg-opacity-10 -m-12 p-12 rounded-medium  overflow-hidden">
										<Link
											to={`/ai`}
											data-umami-event="click-learn-more-about-ai-features">
											<div className="flex flex-row gap-x-12 mr-10">
												<>
													<div className="bg-porcelain w-[75px] h-[75px] rounded-medium overflow-hidden flex-none">
														<div className="bg-[linear-gradient(222.41deg,_#246EA7_4.14%,_#062440_100%)] rounded-medium w-full h-full overflow-hidden flex justify-center items-center">
															<svg
																width="60"
																height="60"
																viewBox="0 0 60 60"
																fill="none"
																xmlns="http://www.w3.org/2000/svg">
																<path
																	fillule="evenodd"
																	clipRule="evenodd"
																	d="M49.6856 19.4837C49.8924 19.5664 49.9972 19.7781 49.9999 19.9915C50.0027 20.2105 49.8979 20.4314 49.6856 20.5163C49.5183 20.5832 49.3526 20.6532 49.1886 20.7261C48.7902 20.9033 48.4016 21.0981 48.0238 21.3095C47.9649 21.3425 47.9063 21.3758 47.848 21.4096C45.6954 22.6549 43.9049 24.4454 42.6596 26.598C42.6259 26.6562 42.5926 26.7147 42.5597 26.7735C42.3482 27.1514 42.1533 27.5401 41.9761 27.9386C41.9032 28.1026 41.8332 28.2683 41.7663 28.4356C41.5986 28.8548 40.9013 28.8548 40.7337 28.4356C40.6668 28.2683 40.5968 28.1026 40.5239 27.9386C40.3467 27.5401 40.1518 27.1514 39.9403 26.7735C39.9074 26.7147 39.8741 26.6562 39.8404 26.598C38.5951 24.4454 36.8046 22.6549 34.652 21.4096C34.5938 21.3759 34.5353 21.3426 34.4765 21.3097C34.0986 21.0982 33.7099 20.9033 33.3114 20.7261C33.1474 20.6532 32.9817 20.5832 32.8144 20.5163C32.3952 20.3487 32.3952 19.6514 32.8144 19.4837C32.9817 19.4168 33.1474 19.3468 33.3114 19.2739C33.7099 19.0967 34.0986 18.9018 34.4765 18.6903C34.5353 18.6574 34.5938 18.6241 34.652 18.5904C36.8046 17.3451 38.5951 15.5546 39.8404 13.402C39.8741 13.3438 39.9074 13.2852 39.9403 13.2264C40.1518 12.8486 40.3467 12.4599 40.5239 12.0614C40.5968 11.8974 40.6668 11.7317 40.7337 11.5645C40.8177 11.3544 41.0347 11.2496 41.2514 11.25C41.4672 11.2505 41.6827 11.3553 41.7663 11.5645C41.8332 11.7317 41.9032 11.8974 41.9761 12.0614C42.1533 12.4599 42.3482 12.8486 42.5597 13.2265C42.5926 13.2853 42.6259 13.3438 42.6596 13.402C43.9049 15.5546 45.6954 17.3451 47.848 18.5904C47.9061 18.6241 47.9646 18.6573 48.0232 18.6902C48.4012 18.9017 48.79 19.0966 49.1886 19.2739C49.3526 19.3468 49.5183 19.4168 49.6856 19.4837Z"
																	fill="white"
																/>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M35.0216 33.6283C30.7021 31.5404 27.2097 28.0479 25.1217 23.7285C25.0607 23.6023 25.0009 23.4754 24.9424 23.3479C24.7303 22.886 24.5341 22.4151 24.3545 21.9358C24.3074 21.8102 24.2615 21.6841 24.2167 21.5573C24.072 21.1476 23.428 21.1476 23.2833 21.5573C23.2385 21.6841 23.1926 21.8102 23.1455 21.9358C22.9659 22.4151 22.7697 22.886 22.5576 23.3479C22.4991 23.4754 22.4393 23.6023 22.3783 23.7285C20.2903 28.0479 16.7979 31.5404 12.4785 33.6283C12.3521 33.6894 12.225 33.7493 12.0972 33.8079C11.6356 34.0199 11.1649 34.216 10.6858 34.3955C10.5602 34.4426 10.4341 34.4885 10.3073 34.5333C10.0957 34.6081 9.99333 34.816 10.0003 35.0196C10.0069 35.2101 10.1092 35.3968 10.3073 35.4668C10.4341 35.5115 10.5602 35.5575 10.6858 35.6045C11.1653 35.7842 11.6365 35.9805 12.0986 36.1927C12.2259 36.2512 12.3525 36.3109 12.4785 36.3717C16.7979 38.4597 20.2903 41.9521 22.3783 46.2716C22.4394 46.3979 22.4993 46.525 22.5579 46.6528C22.7699 47.1144 22.966 47.5851 23.1455 48.0642C23.1926 48.1898 23.2385 48.3159 23.2833 48.4427C23.3581 48.6545 23.5662 48.7568 23.7699 48.7497C23.9603 48.743 24.1468 48.6407 24.2167 48.4427C24.2615 48.3159 24.3074 48.1898 24.3545 48.0642C24.5342 47.5847 24.7305 47.1135 24.9427 46.6514C25.0012 46.5241 25.0608 46.3975 25.1217 46.2716C27.2097 41.9521 30.7021 38.4597 35.0215 36.3717C35.1477 36.3107 35.2746 36.251 35.4021 36.1924C35.864 35.9803 36.3349 35.7841 36.8142 35.6045C36.9398 35.5575 37.0659 35.5115 37.1927 35.4668C37.6024 35.322 37.6024 34.6781 37.1927 34.5333C37.0659 34.4885 36.9398 34.4426 36.8142 34.3955C36.3349 34.2159 35.864 34.0197 35.4021 33.8077C35.2746 33.7491 35.1477 33.6893 35.0216 33.6283Z"
																	fill="white"
																/>
															</svg>
														</div>
													</div>
													<ArticlePreview
														variant="tiny"
														truncateAfterLines={2}
														category="AI Features"
														title="Discover our new AI features overview - how AI  makes your work easier!"
													/>
												</>
											</div>
										</Link>
									</div>
								</div>

								{recentBlog.edges.map(edge => (
									<div key={edge.node.id} className="flex-1">
										<Link to={`/blog/${edge.node.slug}`}>
											<div className="flex flex-row gap-x-12 mr-10">
												<>
													<div className="bg-porcelain w-[75px] h-[75px] rounded-medium overflow-hidden flex-none">
														<GatsbyImage
															className="rounded-medium w-full h-full overflow-hidden"
															imgClassName="overflow-hidden rounded-medium "
															objectFit="cover"
															loading="eager"
															objectPosition="center center"
															image={
																edge.node.teaser_image.imgixImage
																	.gatsbyImageData
															}
															alt={edge.node.title}
															title={edge.node.title}
														/>
													</div>
													<ArticlePreview
														variant="tiny"
														truncateAfterLines={2}
														// category={edge.node.frontmatter.category}
														date={edge.node.published_at}
														title={edge.node.title}
													/>
												</>
											</div>
										</Link>
									</div>
								))}
							</div>
						</div>
					</Grid>

					<Container>
						<h1 className="pb-spacer-lg text-center display1">
							Drive digital transformation
						</h1>
					</Container>

					<Grid className="gap-y-40 xl:gap-y-0 -mb-100 md:-mb-120">
						<Card className="col-span-12 md:col-span-6 xl:col-span-3">
							<Monitor className="text-pictonblue w-80 h-80" />
							<h2 className="mt-spacer-sm mb-12 display3">Intuitive</h2>
							<p className="text-md">
								Cloud Fleet Manager is structured in a user-friendly and intuitive
								way. No more extensive trainings.
							</p>
						</Card>
						<Card className="col-span-12 md:col-span-6 xl:col-span-3">
							<Connections className="text-solaris w-80 h-80" />
							<h2 className="mt-spacer-sm mb-12 display3">All-in-one</h2>
							<p className="text-md">
								A multitude of applications that are optimised for all the different
								departments of your company.
							</p>
						</Card>
						<Card className="col-span-12 md:col-span-6 xl:col-span-3">
							{/* <Connections width={80} height={80} /> */}
							<Globe className="text-atlantis w-80 h-80" />
							<h2 className="mt-spacer-sm mb-12 display3">Worldwide access</h2>
							<p className="text-md">
								The system is completely web-based. Access modules and data
								regardless of time or location.
							</p>
						</Card>
						<Card className="col-span-12 md:col-span-6 xl:col-span-3">
							<Brain className="text-tealblue w-80 h-80" />
							<h2 className="mt-spacer-sm mb-12 display3">Future-ready</h2>
							<p className="text-md">
								AI-assisted capabilities help your business today and support
								scaling for the future.
							</p>
						</Card>
					</Grid>
				</div>

				<div className="mt-100 md:mt-120">
					<Container>
						<h3 className="text-center display3">
							Trusted by ship management companies around the world
						</h3>

						<div className="mt-30 h-[113px] flex items-center">
							<CustomerCarousel className="w-full" />
						</div>

						{/* <div className="mt-30 grid w-full grid-cols-2 gap-20 lg:grid-cols-4 lg:gap-12">
						{customers.edges.slice(0, 4).map(({ node }, index) => {
							return (
								<div
									key={`customer-${index}`}
									className="my-20 w-full flex flex-row items-center">
									<img
										loading="lazy"
										src={node.logo?.localFile.publicURL}
										title={node.name}
										alt={node.name}
										height="40"
										className="mx-auto h-30"
										key={`customer-logo-${index}`}
									/>
								</div>
							)
						})}
					</div> */}
					</Container>
				</div>

				<div className="">
					<Container>
						<div className="bg-pictonblue bg-opacity-10 p-30 rounded-medium flex flex-col xl:flex-row gap-30 items-start">
							<div className="flex-none w-full xl:w-650 xl:order-last">
								<ModalVideo
									event="video-discover-our-new-ai-features"
									// className="-mb-[100px] md:-mb-[120px] xl:-mb-[160px]"
									poster={AiFeaturesPosterImage}
									previewSources={[
										{
											src: AiFeaturesPreviewMp4Video,
											type: "video/mp4",
										},
										{
											src: AiFeaturesPreviewWebMVideo,
											type: "video/webm; codecs=vp9,vorbis",
										},
									]}
									sources={[
										{
											src: AiFeaturesMp4Video,
											type: "video/mp4",
										},
									]}
								/>
							</div>
							<div className="flex flex-col gap-y-30 xl:pl-30 flex-1 xl:order-first">
								<h1 className="display1 xl:mr-20">
									Our <span className="text-tealblue">newest AI Features</span>{" "}
									and how they can make your work easier
								</h1>
								<div>
									<Link to="/ai">
										<Button
											primary={false}
											label="Learn more"
											data-umami-event="click-learn-more-about-ai-features"
										/>
									</Link>
								</div>
							</div>
						</div>
					</Container>
				</div>

				{/* <GatsbyImage
					image={breakerImage.gatsbyImageData}
					width={1800}
					alt="Cloud Fleet Manager in action"
					placeholder="blurred"
					className="mr-20 md:mr-30 lg:mr-160 h-240 md:h-400 lg:h-550 xl:h-700"
					imgClassName="rounded-r-medium overflow-hidden"
				/>

				<Grid className="gap-y-60" containerClassName="mt-80 mb-120 bg-white">
					{testimonials.edges
						.sort(() => 0.5 - Math.random())
						.slice(0, 1)
						.map(({ node }, index) => {
							return (
								<div
									key={`testimonial-${index}`}
									className="col-start-1 col-end-13 lg:col-start-2 lg:col-end-11">
									<h2 className="display2 font-bold">
										&bdquo;{node.quote}&ldquo;
									</h2>
									<div className="mt-30 flex flex-row items-center">
										{node.photo && (
											<GatsbyImage
												image={getImage(node.photo?.localFile)}
												alt={node.photo?.alternativeText}
												className="w-40 h-40 mr-12 rounded-full overflow-hidden"
												imgClassName="rounded-full overflow-hidden"
											/>
										)}
										<span className="text-nevada">
											{node.name}, {node.customer.name}
										</span>
									</div>
								</div>
							)
						})}
				</Grid> */}

				{/* Checklist */}
				<div className="bg-porcelain py-spacer-xl flex flex-col items-center">
					<Checklist
						title="What we can do for your company"
						text="We cover your needs. Increase collaboration and streamline processes for all different departments."
						items={[
							"Worldwide access",
							"Flexible expansion",
							"Data transfer from external systems",
							"Integration of the fleet into all processes",
							"Integration of your existing systems",
							"Access to Cloud Fleet Manager interfaces (API)",
							"Software as a Service (SaaS)",
							"Solutions for all your departments",
						]}
					/>
					<div className="mt-spacer-md text-center">
						<Link to="/faq">
							<Button primary={false}>See our FAQ</Button>
						</Link>
					</div>
				</div>

				<div>
					<Container>
						<div className="flex flex-col lg:flex-row gap-40">
							<div className="lg:w-1/2">
								<GatsbyImage
									image={breakerImage.gatsbyImageData}
									alt="Cloud Fleet Manager in action"
									placeholder="blurred"
									className="w-full h-full"
									imgClassName="rounded-medium overflow-hidden"
								/>
							</div>

							{/* Testimonal */}

							{testimonials.edges
								.sort(() => 0.5 - Math.random())
								.slice(0, 1)
								.map(({ node }, index) => {
									return (
										<div
											className="flex flex-col gap-y-30 lg:w-1/2 lg:pl-20 lg:pr-40 justify-center"
											key={`testimonial-${index}`}>
											<div
												className="flex flex-col gap-y-20"
												key={`testimonial-${index}`}>
												<h1 className="font-sans font-semibold text-sm leading-18 tracking-wide uppercase text-tealblue">
													What the industry is saying
												</h1>
												<p className="display3 reg">
													&bdquo;{node.quote}&ldquo;
												</p>
											</div>
											<div className="flex flex-row gap-x-12 items-center">
												<div className="w-60 h-60 rounded-full overflow-hidden">
													{node.photo && (
														<GatsbyImage
															image={getImage(node.photo?.localFile)}
															alt={node.photo?.alternativeText}
															className="w-full h-full rounded-full overflow-hidden"
															imgClassName="rounded-full overflow-hidden"
														/>
													)}
												</div>
												<div className="flex flex-col gap-y-10">
													<h3 className="font-sans font-medium text-md leading-26 text-jetblack">
														{node.name}
													</h3>
													<p className="font-sans font-normal text-md leading-26 text-nevada">
														{node.customer.name}
													</p>
												</div>
											</div>
										</div>
									)
								})}
						</div>
					</Container>
				</div>

				<div className="bg-porcelain">
					<Container className="my-spacer-xl">
						<div className="flex flex-col gap-y-60">
							<h1 className="display1">Most popular applications</h1>
							<div className="flex flex-col xl:flex-row gap-40">
								{popularApplications.nodes.map(
									(
										{
											slug,
											product_package,
											seo: { title },
											hero: { description, image },
										},
										index,
									) => (
										<Link
											to={`/${product_package.slug}/${slug}`}
											className="flex-1 bg-white rounded-medium overflow-hidden flex flex-col"
											key={`product-${index}`}>
											<div
												className={`h-240 border border-b-8 border-b-${rainbow[index]}`}>
												<GatsbyImage
													image={getImage(image.localFile)}
													alt={`${title} in action`}
													placeholder="blurred"
													className="w-full h-full"
													imgClassName=""
												/>
											</div>
											<div className="flex flex-col gap-y-10 flex-none my-20 mx-30">
												<h2 className="display3">{title}</h2>
												<div>{description}</div>
											</div>
										</Link>
									),
								)}
							</div>
						</div>
					</Container>
				</div>

				{/* <div className="bg-white">
					<RecentNewFeatures />
				</div> */}

				<Teaser image={getImage(brochureImage)}>
					<div className="flex flex-col justify-center h-full">
						{/* grow */}
						<div className="bg-porcelain rounded-medium p-40 flex flex-col justify-start items-start gap-30">
							<div className="flex flex-col justify-start items-start gap-12">
								<div className="text-tealblue captionBtn">Brochure</div>
								<h2 className="display2">Cloud Fleet Manager Product Portfolio</h2>
								<p className="bodyp">
									Learn in depth what Cloud Fleet Manager has to offer and how the
									cloud-based approach helps shipping companies.
								</p>
							</div>
							<a href="/Cloud-Fleet-Manager-Product-Brochure.pdf" download>
								<Button
									primary={false}
									label="Download"
									data-umami-event="download-product-brochure"
								/>
							</a>
						</div>
					</div>
				</Teaser>

				<BlueBanner
					className="pb-spacer-lg"
					title={page.banner.title}
					subtitle={page.banner.pretext}
					image={
						page.banner.screenshot ? getImage(page.banner.screenshot.localFile) : null
					}
					callToAction="Schedule a demo"
					callToActionUrl="/demo"
				/>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		page: strapiHomePage {
			seo {
				title
				description
				keywords
			}

			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}

			introductionVideo {
				title
				posterImage {
					alternativeText
					localFile {
						publicURL
						url
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 640)
						}
					}
				}

				video {
					localFile {
						publicURL
						url
					}
				}
			}
		}

		testimonials: allStrapiTestimonial(filter: { emphasize: { eq: true } }) {
			edges {
				node {
					name
					quote
					customer {
						name
					}
					photo {
						alternativeText
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(width: 40, height: 40)
							}
						}
					}
				}
			}
		}

		customers: allStrapiCustomer(
			filter: { emphasize: { eq: true }, logo: { mime: { eq: "image/svg+xml" } } }
		) {
			edges {
				node {
					name
					logo {
						alternativeText
						localFile {
							publicURL
						}
					}
				}
			}
		}

		allCustomers: allStrapiCustomer(filter: { logo: { mime: { eq: "image/svg+xml" } } }) {
			totalCount
		}

		recentBlog: allMongodbBloghanseaticsoftArticles(
			sort: { order: DESC, fields: published_at }
			filter: { isFuture: { eq: false } }
			limit: 2
		) {
			edges {
				node {
					title
					excerpt
					published_at(formatString: "MMMM DD, YYYY")
					slug
					teaser_image {
						imgixImage {
							gatsbyImageData(layout: CONSTRAINED, width: 75, height: 75)
						}
					}
				}
			}
		}

		allPressCoverage(limit: 3, sort: { fields: released_at, order: DESC }) {
			nodes {
				id
				url
				released_at(formatString: "MMMM DD, YYYY")
				publication
				headline
			}
		}

		popularApplications: allStrapiProduct(
			filter: { seo: { title: { in: ["CFM Crewing", "CFM Purchase", "CFM Maintenance"] } } }
			sort: { fields: seo___title }
		) {
			nodes {
				slug
				hero {
					title
					description
					image {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(layout: CONSTRAINED, height: 240)
							}
						}
					}
				}
				seo {
					title
				}
				product_package {
					title
					slug
				}
			}
		}

		breakerImage: imgixImage(
			url: "https://hanseaticsoftblog.blob.core.windows.net/website/gatsby/breaker.png"
		) {
			gatsbyImageData(layout: CONSTRAINED, width: 1800, placeholder: NONE, imgixParams: {})
		}

		brochureImage: file(relativePath: { eq: "brochure.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, width: 760)
			}
		}
	}
`

export default IndexPage
