// , { useEffect }
import React from "react"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

// import { Button } from "./Button"

const Simple = ({ className, children }) => {
	return (
		<div
			className={`px-30 py-50 bg-white shadow-xl rounded-medium overflow-hidden ${
				className || ""
			}`}>
			{children}
		</div>
	)
}
// const Advanced = ({ category, title, text, image, button, className }) => (
// 	<div
// 		className={`flex flex-col bg-porcelain rounded-large overflow-hidden relative z-10 ${className}`}>
// 		<div className="overflow-hidden relative z-10">
// 			{image && (
// 				<GatsbyImage
// 					image={getImage(image.childImageSharp.gatsbyImageData)}
// 					alt={title}
// 					className="w-full h-240 object-cover cursor-pointer duration-500 transform hover:scale-125 z-0"
// 				/>
// 			)}
// 		</div>

// 		<div className="px-30 py-40 flex flex-col items-center justify-center">
// 			{category && <h4 className="font-bold text-pictonblue uppercase mb-12">{category}</h4>}
// 			<h3 className="mb-12 text-center display3">{title}</h3>
// 			<p className="small text-center">{text}</p>
// 			{button && (
// 				<div className="mt-24">
// 					<Button primary={true}>{button}</Button>
// 				</div>
// 			)}
// 		</div>
// 	</div>
// )

export { Simple }
// , Advanced
